body {
  font-family: "Nunito", sans-serif;
  background-image: url("/src/assets/background-pictures/main_background.jpg");
  background-size: cover;
  background-position: center;
  margin: 0;
  padding: 0;
}

.app-container {
  width: 100%;
  height: auto;
  display: block;
  box-sizing: border-box;
}

.main-section {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}

@media (max-width: 320px) {
  h1 {
    font-size: 20px;
  }
}

/* tablet */

@media (min-width: 321px) and (max-width: 768px) {
  h1 {
    font-size: 24px;
  }
}

/* desktop */

@media (min-width: 769px) {
  h1 {
    font-size: 28px;
  }
}
