.hourly-section {
  width: 90%;
  height: auto;
  margin-bottom: 50px;
}

.hourly-section .hourly-tag {
  width: fit-content;
  padding: 10px 20px;
  margin: 10px 0;
  box-sizing: border-box;
  background-image: linear-gradient(
    to bottom right,
    rgba(14, 41, 41, 0.8),
    rgba(45, 75, 75, 0.8)
  );
  border-radius: 10px;
  color: #fff;
  font-weight: 700;
}

.day {
  background-image: linear-gradient(
    to bottom right,
    rgba(202, 234, 236, 1),
    rgba(255, 255, 255, 0.7)
  );
  color: rgb(19, 54, 54);
}

.night {
  background-image: linear-gradient(
    to bottom right,
    rgba(66, 90, 92, 1),
    rgba(255, 255, 255, 0.7)
  );
  color: #fff;
}

.hourly-section .hourly-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  padding: 20px 20px 30px;
}

.hourly-card .hour {
  text-align: center;
  font-weight: 700;
}

.hourly-card img {
  width: 70px;
  height: 70px;
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.3));
}

.hourly-card .condition {
  text-align: center;
  font-weight: 700;
}

.hourly-card .degree {
  font-size: 18px;
  font-weight: 700;
  margin: 10px 0;
}

.hourly-card hr {
  width: 100%;
  margin: 10px 0 15px;
  border: none;
  border-bottom: 1px dotted;
}

.hourly-card .details {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.hourly-card .details img {
  width: 20px;
  height: 20px;
}

.hourly-card .detail-units {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.hourly-card p {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

/* mobile */

@media (max-width: 320px) {
  .hourly-card .hour {
    font-size: 16px;
  }

  .hourly-card .condition {
    height: 42px;
    font-size: 14px;
  }

  .hourly-section .hourly-tag {
    font-size: 14px;
  }
}

/* tablet */

@media (min-width: 321px) and (max-width: 768px) {
  .hourly-card .hour {
    font-size: 16px;
  }

  .hourly-card .condition {
    height: 42px;
    font-size: 14px;
  }

  .hourly-section .hourly-tag {
    font-size: 16px;
  }
}

/* desktop */

@media (min-width: 769px) {
  .hourly-card .hour {
    font-size: 18px;
  }

  .hourly-card .condition {
    height: 35px;
    font-size: 16px;
  }

  .hourly-section .hourly-tag {
    font-size: 16px;
  }
}
