.photo-source {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  text-align: end;
  margin-bottom: 10px;
  padding: 0 20px;
  font-weight: 200;
}

.day a {
  text-decoration: underline;
  color: rgb(19, 54, 54);
}

.night a {
  text-decoration: underline;
  color: #fff;
}

.day {
  color: rgb(19, 54, 54);
}

.night {
  color: #fff;
}

@media (max-width: 320px) {
  .photo-source {
    font-size: 12px;
  }

  .photo-source img {
    width: 15px;
    height: 15px;
  }
}

/* tablet */

@media (min-width: 321px) and (max-width: 768px) {
  .photo-source {
    font-size: 12px;
  }

  .photo-source img {
    width: 15px;
    height: 15px;
  }
}

/* desktop */

@media (min-width: 769px) {
  .photo-source {
    font-size: 14px;
  }

  .photo-source img {
    width: 20px;
    height: 20px;
  }
}
