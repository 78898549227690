.main-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.forecasts {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
