/* main */
.current-card {
  width: 90vw;
  max-width: 600px;
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  border-radius: 20px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
}

.day {
  background-image: linear-gradient(
    to bottom right,
    rgba(202, 234, 236, 1),
    rgba(255, 255, 255, 0.7)
  );
  color: rgb(19, 54, 54);
}

.night {
  background-image: linear-gradient(
    to bottom right,
    rgba(66, 90, 92, 1),
    rgba(255, 255, 255, 0.7)
  );
  color: #fff;
}

/* top-section */

.current-card .top-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.current-card .top-section .country {
  margin-bottom: 10px;
  font-weight: 400;
  margin-bottom: 10px;
}

.current-card .top-section .date {
  font-weight: 400;
}

.current-card .top-section .today {
  margin-top: 10px;
}

.current-card .top-section .top-right-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-left: 15px;
}

.current-card .top-section .top-right-section h2 {
  margin: 10px 0 0;
}

.current-card .top-section .top-right-section .condition {
  text-align: center;
}

.current-card .top-section .top-right-section .condition img {
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.3));
}

.current-card .top-section h3 {
  font-weight: 700;
  margin: 0;
}

.current-card .top-section h4 {
  font-weight: 700;
  margin: 0;
}

/* separator */

.current-card hr {
  width: 100%;
  margin: 15px 0;
  border: none;
  border-bottom: 1px dotted;
}

/* bottom-section - details */

.current-card .details {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  font-weight: 700;
}

.current-card .details .detail-sections {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.current-card .details .detail-units {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.current-card .details .detail-units p {
  margin: 0;
  text-align: end;
}

/* mobile */

@media (max-width: 320px) {
  .current-card {
    padding: 20px;
  }

  .current-card .top-section .country,
  .current-card .top-section .date {
    font-size: 12px;
  }

  .current-card .top-section .top-right-section .condition img {
    width: 100px;
    height: 90px;
    object-fit: cover;
  }

  .current-card .top-section .top-right-section h2 {
    font-size: 25px;
  }

  .current-card .top-section h3 {
    font-size: 18px;
  }

  .current-card .top-section h4 {
    font-size: 16px;
  }

  .current-card .details .detail-units img {
    width: 20px;
    height: 20px;
  }

  .current-card .details .detail-units p {
    height: 14px;
    line-height: 14px;
    font-size: 14px;
  }
}

/* tablet */

@media (min-width: 321px) and (max-width: 768px) {
  .current-card {
    padding: 25px;
  }

  .current-card .top-section .country,
  .current-card .top-section .date {
    font-size: 14px;
  }

  .current-card .date {
    font-size: 10px;
  }

  .current-card .top-section .top-right-section .condition img {
    width: 90px;
    height: 80px;
    object-fit: cover;
  }

  .current-card .top-section .top-right-section h2 {
    font-size: 25px;
  }

  .current-card .top-section h3 {
    font-size: 24px;
  }

  .current-card .top-section h4 {
    font-size: 18px;
  }

  .current-card .details .detail-units img {
    width: 20px;
    height: 20px;
  }

  .current-card .details .detail-units p {
    height: 14px;
    line-height: 14px;
    font-size: 14px;
  }
}

/* desktop */

@media (min-width: 769px) {
  .current-card {
    padding: 30px;
  }

  .current-card .top-section .country,
  .current-card .top-section .date {
    font-size: 15px;
  }

  .current-card .top-section .top-right-section .condition img {
    width: 130px;
    height: 130px;
  }

  .current-card .top-section .top-right-section h2 {
    font-size: 50px;
  }

  .current-card .top-section h3 {
    font-size: 30px;
  }

  .current-card .top-section h4 {
    font-size: 18px;
  }

  .current-card .details .detail-units img {
    width: 30px;
    height: 30px;
  }

  .current-card .details .detail-units p {
    height: 18px;
    line-height: 18px;
    font-size: 18px;
  }
}
