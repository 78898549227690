.header {
  display: flex;
  flex-direction: column;
}

.header h1 {
  box-sizing: border-box;
  margin: 0;
  padding: 10px 20px;
  background-image: linear-gradient(
    to bottom right,
    rgba(14, 41, 41, 0.8),
    rgba(45, 75, 75, 0.8)
  );
  color: #fff;
}
