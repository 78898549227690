.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-bottom: 4px solid rgb(23, 63, 63);
  border-left: 4px solid transparent;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-card {
  width: 200px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: auto;
  padding: 30px;
  border-radius: 20px;
  background-image: linear-gradient(
    to bottom right,
    rgba(202, 234, 236, 0.9),
    rgba(255, 255, 255, 0.5)
  );
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
}

.loading-text {
  width: auto;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  color: rgb(23, 63, 63);
}
